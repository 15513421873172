<template>
  <div class="position-relative">
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <img class="logo-container" src="@/assets/logo.svg" />
    <v-row class="no-gutters login-container">
      <!-- Left container -->
      <v-col class="col-3 image-container exact-center">
        <ImageSection />
      </v-col>
      <!-- Right container (form section) -->
      <v-col class="exact-center">
        <div class="form-container exact-center">
          <div class="form-width">
            <div class="font-weight-500 header">Login</div>
            <div class="font-weight-500 login-header mt-7">
              Login to your Account
            </div>
            <div class="content">You need below credentials to login!</div>
            <!-- Login user type option -->
            <v-radio-group
              v-model="row"
              @change="togglePortal"
              row
              class="mt-7"
              style="font-size: 10px"
            >
              <v-radio
                label="Merchant"
                color="#ff003f"
                value="merchant"
              ></v-radio>
              <v-radio
                label="Logistic"
                color="#ff003f"
                value="logistic"
              ></v-radio>
            </v-radio-group>
            <!-- Form field -->
            <TextField
              label="Email"
              :required="true"
              :value="formData.email_address"
              @change="getFormData($event.toLowerCase(), 'email_address')"
              :errorMessages="emailErrors"
              :error="emailErrors && emailErrors.length > 0"
              @input="$v.formData.email_address.$touch()"
              @blur="$v.formData.email_address.$touch()"
              @onEnter="enterClicked"
            />
            <TextField
              label="Password"
              :required="true"
              :value="formData.password"
              @change="getFormData($event, 'password')"
              inputType="password"
              @onEnter="enterClicked"
            />
            <div class="error-message">{{ errorMessage }}</div>
            <div class="no-gutters form-width justify-end mb-4">
              <!-- <div>
                <v-checkbox color="#ff003f" label="Remember Me" />
              </div> -->
              <div class="reset-password cursor-pointer" @click="goToEmailPage">
                Reset Password ?
              </div>
            </div>
            <div class="justify-end">
              <Button
                type="primary"
                label="Sign in"
                width="94px"
                @click="onSave"
                :disabled="isSubmitDisabled() || isLoading"
                :loading="isLoading"
              />
            </div>
            <div class="content exact-center">
              Dont you have an account yet?
              <a href="https://shiokholdings.com/" target="_blank" class="join-slack cursor-pointer">Join ShiOkParcel</a>
            </div>
          </div>
        </div>
        <div class="position-absolute login-rights">
          2021 Shiok All rights reserved
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ImageSection from "./ImageSection.vue";
export default {
  name: "LoginIndex",
  mixins: [validationMixin],
  components: {
    ImageSection,
  },
  validations: {
    formData: {
      email_address: { required, email },
      password: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      notificationMessage: "",
      isNotification: false,
      notificationKey: 0,
      formData: {
        email_address: "",
        password: "",
      },
      row: "",
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions({
      adminLogin: "admin/authentication/login",
      merchantLogin: "merchant/authentication/login",
      logisticsLogin: "logistics/authentication/login",
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    togglePortal(type) {
      this.row = type;
      this.$router.push(`/auth/login/${type}`);
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    enterClicked() {
      if (!this.isSubmitDisabled()) {
        this.onSave();
      }
    },
    navigateTo(){

    },
    async onSave() {
      try {
        this.isLoading = true;
        if (this.row === "logistic") {
          await this.logisticsLogin(this.formData);
          this.errorMessage = "";
          this.logisticLoggedUserPermission.management_dashboard
            ? this.$router.push("/logistics/dashboard")
            : this.$router.push("/logistics/requests");
        } else if (this.row === "merchant") {
          await this.merchantLogin(this.formData);
          this.errorMessage = "";
          this.merchantLoggedUserPermission.management_dashboard
            ? this.$router.push("/merchant/dashboard")
            : this.$router.push("/merchant/orders");
        } else {
          await this.adminLogin(this.formData);
          this.errorMessage = "";
          this.adminLoggedUserPermission.management_dashboard
            ? this.$router.push("/admin/dashboard")
            : this.$router.push("/admin/merchant");
        }
        this.isLoading = false;
      } catch (err) {
        const { data } = err.response;
        this.errorMessage = data?.message;
        this.isLoading = false;
      }
    },
    goToEmailPage() {
      const type = this.$route.params.type;
      this.$router.push(`/auth/verify-email/${type}`);
    },
  },
  created() {
    this.row = this.$route?.params?.type;
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email_address.$dirty) return errors;
      !this.$v.formData.email_address.email &&
        errors.push("Must be valid e-mail");
      return errors;
    },
    ...mapGetters({
      adminLoggedUserPermission: "admin/authentication/loggedUserPermission",
      merchantLoggedUserPermission:
        "merchant/authentication/loggedUserPermission",
      logisticLoggedUserPermission:
        "logistics/authentication/loggedUserPermission",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@import "@/styles/auth.scss";

.join-slack{
  text-decoration: none;
}
.error-message {
  margin-top: -14px;
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: red;
  height: 20px;
}
</style>
